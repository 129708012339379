.mid-col {
    height: 150px;
    width: 40%;
    display: block;
    float: left;
}

.side-col {
    height: 150px;
    width: 30%;
    display: block;
    float: left;
}


.mid-col > h1 {
    font-size: 36px;
    line-height: 1.4;
    letter-spacing: 0px;
    color: #333;
    font-family: "Georgia",Times,serif;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 10px;
}
.mid-col > h4 {
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0px;
    color: #333;
    font-family: "Georgia",Times,serif;
    font-weight: 400;
    line-height: 1.4;
    text-transform: none;
    font-style: normal;
    margin-top: 10px;
    margin-bottom: 10px;
}

.side-col > img {
    max-width: 100%;
    max-height: 100%;
    padding: 10px;
}

.round-img {
    border-radius: 100%;
}

@media only screen and (max-width: 860px) {
    .mid-col {
        width: 100%;
        float: none;
    }
    .side-col {
        width: 100%;
        float: none;
    }
}

.form {
    padding: 0 5%;
    height: 250px;
}

.form input, .form textarea {
    width: 100%;
    margin: 12px 0 3px 0;
    padding: 6px 12px;
    font: inherit;
    border-radius: 4px;
    border: 0px;
}

.form textarea {
    height: 100px;
}

.form button {
    float: left;
    border-radius: 4px;
    border: 1px solid grey;
    padding: 10px 20px;
    font: inherit;
    display: block;
}

.disabled {
    color: #1010104D;
    background-color: #EFEFEF4D;
}

.sending {
    background-color: yellow;
}

.sent {
    background-color: green;
}

.failed {
    background-color:rgba(128, 0, 0, 0.678);
}

.errors {
    float: left;
    padding: 0 3px;
    color: maroon;
    display: block;
}

.hidden {
    display: none;
}

#website {
    display: none;
}


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}


html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-image: url('./background.jpeg');
  width: 100%;
}

main {
  text-align: center;
  min-height: 84vh;
}

section {
  height: auto;
  text-align: center;
}

.main-content {
  background-color: white;
  width: 80%;
  min-height: 100vh;
  margin: 0 auto;
}

.content {
  margin: auto;
  width: 90%;
  padding: 5vh;
}

h1,
h2,
h3,
li,
p {
  color: #042b5f;
}

h1 {
  margin: 0;
  padding: 25px 0 0 0;
}

@media only screen and (max-width: 860px) {
  .main-content {
    width: 100%;
    min-width: 320px
  }
}

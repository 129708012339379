.icons {
    height: auto;
    width: 100%;
    padding: 20px 0;
}

.wrapper {
    width: 33.33%;
    display: inline-block;
}

/* Below puts circle behind icon */
.icon-container {
    width: 60%;
    height: 60%;
    padding: 15%;
    border-radius: 50%;
    background-color: #334350;
    margin: 0 20%;
}

.icon-container > .icon {
    max-width: 60px;
    font-size: 60px;
    color: #ffffff;
}

.caption {
    color: #333;
    font-family: "Georgia",Times,serif;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    font-size: 36px;
}

@media only screen and (max-width: 860px) {
    .wrapper {
        width: 100%;
        display: block;
        padding: 15px 25px;
        margin: auto;
    }
    .icon-container > .icon {
        max-width: 90px;
        font-size: 90px;
    }
}

@media only screen and (max-width: 496px) {
    .icon-container > .icon {
        max-width: 60px;
        font-size: 60px;
        color: #ffffff;
    }
}
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}


html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-image: url(https://spf-fitness.gitlab.io/front-end/static/media/background.7267b8ed.jpeg);
  width: 100%;
}

main {
  text-align: center;
  min-height: 84vh;
}

section {
  height: auto;
  text-align: center;
}

.main-content {
  background-color: white;
  width: 80%;
  min-height: 100vh;
  margin: 0 auto;
}

.content {
  margin: auto;
  width: 90%;
  padding: 5vh;
}

h1,
h2,
h3,
li,
p {
  color: #042b5f;
}

h1 {
  margin: 0;
  padding: 25px 0 0 0;
}

@media only screen and (max-width: 860px) {
  .main-content {
    width: 100%;
    min-width: 320px
  }
}

.list-container {
    text-align: center;
}

.list-container ul {
    width: auto; 
    display:inline-block;
}

.mid-col {
    height: 150px;
    width: 40%;
    display: block;
    float: left;
}

.side-col {
    height: 150px;
    width: 30%;
    display: block;
    float: left;
}


.mid-col > h1 {
    font-size: 36px;
    line-height: 1.4;
    letter-spacing: 0px;
    color: #333;
    font-family: "Georgia",Times,serif;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 10px;
}
.mid-col > h4 {
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0px;
    color: #333;
    font-family: "Georgia",Times,serif;
    font-weight: 400;
    line-height: 1.4;
    text-transform: none;
    font-style: normal;
    margin-top: 10px;
    margin-bottom: 10px;
}

.side-col > img {
    max-width: 100%;
    max-height: 100%;
    padding: 10px;
}

.round-img {
    border-radius: 100%;
}

@media only screen and (max-width: 860px) {
    .mid-col {
        width: 100%;
        float: none;
    }
    .side-col {
        width: 100%;
        float: none;
    }
}

.wide-img {
    width: 100%;
    padding: 3vh 0 0 0;
}

.icons {
    height: auto;
    width: 100%;
    padding: 20px 0;
}

.wrapper {
    width: 33.33%;
    display: inline-block;
}

/* Below puts circle behind icon */
.icon-container {
    width: 60%;
    height: 60%;
    padding: 15%;
    border-radius: 50%;
    background-color: #334350;
    margin: 0 20%;
}

.icon-container > .icon {
    max-width: 60px;
    font-size: 60px;
    color: #ffffff;
}

.caption {
    color: #333;
    font-family: "Georgia",Times,serif;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    font-size: 36px;
}

@media only screen and (max-width: 860px) {
    .wrapper {
        width: 100%;
        display: block;
        padding: 15px 25px;
        margin: auto;
    }
    .icon-container > .icon {
        max-width: 90px;
        font-size: 90px;
    }
}

@media only screen and (max-width: 496px) {
    .icon-container > .icon {
        max-width: 60px;
        font-size: 60px;
        color: #ffffff;
    }
}
.form {
    padding: 0 5%;
    height: 250px;
}

.form input, .form textarea {
    width: 100%;
    margin: 12px 0 3px 0;
    padding: 6px 12px;
    font: inherit;
    border-radius: 4px;
    border: 0px;
}

.form textarea {
    height: 100px;
}

.form button {
    float: left;
    border-radius: 4px;
    border: 1px solid grey;
    padding: 10px 20px;
    font: inherit;
    display: block;
}

.disabled {
    color: #1010104D;
    background-color: #EFEFEF4D;
}

.sending {
    background-color: yellow;
}

.sent {
    background-color: green;
}

.failed {
    background-color:rgba(128, 0, 0, 0.678);
}

.errors {
    float: left;
    padding: 0 3px;
    color: maroon;
    display: block;
}

.hidden {
    display: none;
}

#website {
    display: none;
}


.home-footer {
    width: 100%;
    font-family: "Georgia",Times,serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.45;
    padding: 0;
    word-wrap: break-word;
    min-height: 400px;
    background-color: #808080;
}

.footer-section {
    width: 25%;
    display: block;
    float: left;
}

@media only screen and (max-width: 860px) {
    .home-footer {
        height: 1000px;
    }
    .footer-section {
      width: 100%;
      min-height: 200px;
    }
    .footer-section > ul {
        padding: 0 30%;
      }
}

.footer-section > h1 {
    font-size: 36px;
    line-height: 1.4;
    letter-spacing: 0px;
    font-weight: 400;
    padding: 5px;
    color: #ffffff;
}

.footer-section > p, 
.footer-section > ul > li {
    color: #ffffff;
    font-style: normal;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0px;
}

.footer-section > ul {
    list-style-type: none;
}

.map {
    height: 300px;
    width: 90%;
}


.MainHeader_header__2QvI8 {
  width: 100%;
  height: 8vh;
  background-color: #3a84df;
  padding: 0 10%;
}
  
.MainHeader_header__2QvI8 nav {
  height: 100%;
}

.MainHeader_header__2QvI8 ul {
  height: 100%;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.MainHeader_header__2QvI8 li {
  margin: 0 .25rem;
  width: 20rem;
  font-size: .75rem;
  text-align: center;
}

.MainHeader_header__2QvI8 a {
  color: white;
  text-decoration: none;
}

.MainHeader_header__2QvI8 span {
  color: white;
}

.MainHeader_header__2QvI8 span:hover,
.MainHeader_header__2QvI8 a:active,
.MainHeader_header__2QvI8 a.MainHeader_active__20xFN {
  color: #95bcf0;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #95bcf0;
}

.MainHeader_header__2QvI8 a:hover,
.MainHeader_header__2QvI8 a:active,
.MainHeader_header__2QvI8 a.MainHeader_active__20xFN {
  color: #95bcf0;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #95bcf0;
}

.MainHeader_hamburgerContainer__2Vjik {
  display: none;
}

.MainHeader_hidden__1mppK {
  display: none;
}

@media only screen and (max-width: 860px) {


  .MainHeader_header__2QvI8 {
    padding: 0;
    height: auto;
  }

  .MainHeader_header__2QvI8 ul {
    min-width: 100vw;
  }

  .MainHeader_header__2QvI8 ul.MainHeader_hideNav__2sams {
    display: none;
  }

  .MainHeader_header__2QvI8 ul.MainHeader_showNav__3_mDI  {
    height: 180px;
    display: block;
  }
  

  .MainHeader_header__2QvI8 li, .MainHeader_header__2QvI8 span {
    height: 35px;
    background-color: #3a84df;
    text-decoration: none;
    margin: 0;
    width: inherit;
    border-top: 1px solid white;

  }

  .MainHeader_header__2QvI8 a, .MainHeader_header__2QvI8 span  {
    text-decoration: none;
    font-size: 20px;
  }
  
  .MainHeader_header__2QvI8 a:hover,
  .MainHeader_header__2QvI8 a:active,
  .MainHeader_header__2QvI8 a.MainHeader_active__20xFN {
    color: #95bcf0;
    border-bottom: 0px solid #95bcf0;
    height: 100%;
  }

  nav > .MainHeader_hamburgerContainer__2Vjik {
    background: #3a84df;
    border-radius: 25%;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(70, 70, 70);
    height: 4vh;
    width: 35px;
    margin: 2vh;
    display: inline;
    display: initial;
  }

  .MainHeader_hamburger__3ZsBe {
    color: rgb(70, 70, 70);
    font-size: auto;
  }
  
  .MainHeader_hamburgerContainer__2Vjik:hover {
    background-color: #0757c0b7;
    border-radius: 25%;
    border-color: rgb(70, 70, 70);
    border-style: solid;
    height: 4vh;
    width: 35px;
    margin: 2vh;
  }
}  

footer {
  width: 100%;
  height: 8vh;
  background-color: #0757c0b7;
}

footer p {
  margin: 0;
  /* We need to change line-height to get this centered. How? */
  line-height: 7.5vh;
  font-size: .75rem;
  text-align: center;
  color: white;
  vertical-align: middle;
}

span {
  text-decoration: underline;
}

span:hover{
  text-decoration: underline;
  color: #95bcf0;
  cursor: pointer;
}

img.calendar {
    width: 100%;
}
.fitness-class > p, li {
    text-align: left;
}

.fitness-class > ul {
    padding: 0 50px;
}
  


.header {
  width: 100%;
  height: 8vh;
  background-color: #3a84df;
  padding: 0 10%;
}
  
.header nav {
  height: 100%;
}

.header ul {
  height: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.header li {
  margin: 0 .25rem;
  width: 20rem;
  font-size: .75rem;
  text-align: center;
}

.header a {
  color: white;
  text-decoration: none;
}

.header span {
  color: white;
}

.header span:hover,
.header a:active,
.header a.active {
  color: #95bcf0;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #95bcf0;
}

.header a:hover,
.header a:active,
.header a.active {
  color: #95bcf0;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #95bcf0;
}

.hamburgerContainer {
  display: none;
}

.hidden {
  display: none;
}

@media only screen and (max-width: 860px) {


  .header {
    padding: 0;
    height: auto;
  }

  .header ul {
    min-width: 100vw;
  }

  .header ul.hideNav {
    display: none;
  }

  .header ul.showNav  {
    height: 180px;
    display: block;
  }
  

  .header li, .header span {
    height: 35px;
    background-color: #3a84df;
    text-decoration: none;
    margin: 0;
    width: inherit;
    border-top: 1px solid white;

  }

  .header a, .header span  {
    text-decoration: none;
    font-size: 20px;
  }
  
  .header a:hover,
  .header a:active,
  .header a.active {
    color: #95bcf0;
    border-bottom: 0px solid #95bcf0;
    height: 100%;
  }

  nav > .hamburgerContainer {
    background: #3a84df;
    border-radius: 25%;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(70, 70, 70);
    height: 4vh;
    width: 35px;
    margin: 2vh;
    display: initial;
  }

  .hamburger {
    color: rgb(70, 70, 70);
    font-size: auto;
  }
  
  .hamburgerContainer:hover {
    background-color: #0757c0b7;
    border-radius: 25%;
    border-color: rgb(70, 70, 70);
    border-style: solid;
    height: 4vh;
    width: 35px;
    margin: 2vh;
  }
}  

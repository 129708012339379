footer {
  width: 100%;
  height: 8vh;
  background-color: #0757c0b7;
}

footer p {
  margin: 0;
  /* We need to change line-height to get this centered. How? */
  line-height: 7.5vh;
  font-size: .75rem;
  text-align: center;
  color: white;
  vertical-align: middle;
}

span {
  text-decoration: underline;
}

span:hover{
  text-decoration: underline;
  color: #95bcf0;
  cursor: pointer;
}
.home-footer {
    width: 100%;
    font-family: "Georgia",Times,serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.45;
    padding: 0;
    word-wrap: break-word;
    min-height: 400px;
    background-color: #808080;
}

.footer-section {
    width: 25%;
    display: block;
    float: left;
}

@media only screen and (max-width: 860px) {
    .home-footer {
        height: 1000px;
    }
    .footer-section {
      width: 100%;
      min-height: 200px;
    }
    .footer-section > ul {
        padding: 0 30%;
      }
}

.footer-section > h1 {
    font-size: 36px;
    line-height: 1.4;
    letter-spacing: 0px;
    font-weight: 400;
    padding: 5px;
    color: #ffffff;
}

.footer-section > p, 
.footer-section > ul > li {
    color: #ffffff;
    font-style: normal;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0px;
}

.footer-section > ul {
    list-style-type: none;
}

.map {
    height: 300px;
    width: 90%;
}
